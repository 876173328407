import React from "react"
import * as styles from "./styles.module.scss"

const SubHeading = ({ className, children, ...props }) => {
	return (
		<h1 className={`${styles.sectionHeading} ${className}`} {...props}>
			{children}
		</h1>
	)
}

export default SubHeading
