import React from "react"
import Section from "../../../../components/shared/Section"
import SubHeading from "../../../../components/shared/SubHeading"
import { Col, Container, Row } from "react-bootstrap"
import Paragraph from "../../../../components/shared/Paragraph"
import { Link } from "gatsby"

const ResourceLibrary = () => {
	return (
		<Section>
			<Container>
				<Row>
					<Col md={6}>
						<SubHeading className=" text-md-start text-center ">Resource Library</SubHeading>
						<Paragraph className=" text-md-start text-center ">
							Galea x Matt Stevenson Mental Health resource library contains information for athletes experiencing
							depression, performance anxiety, suicidal thoughts/suicidality, and related mental health challenges. The
							resource library also includes mental health resources for parents and coaches as well as sport psychology
							exercises to optimize sport enjoyment and mental performance.
						</Paragraph>
					</Col>
					<Col md={6}>
						<ul className="mt-5">
							<li className="text-secondary">
								<Link className="text-decoration-none text-secondary" to="https://content.galeahealth.com/2021/02/03/depression-symptoms/">Depression in Athletes</Link>
							</li>
							<li className="text-secondary">
								<Link className="text-decoration-none text-secondary" to="https://content.galeahealth.com/2021/12/09/depression-energy-connectedness/">How does Depression Lead to Decreased Energy and Connectedness?</Link>
							</li>
							<li className="text-secondary"><Link className="text-decoration-none text-secondary" to="https://content.galeahealth.com/2021/12/09/what-is-perfectionism/"> What Is Perfectionism</Link> </li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default ResourceLibrary
