import React from "react"
import Section from "../../../../components/shared/Section"
import { Col, Container, Image, Row } from "react-bootstrap"
import Paragraph from "../../../../components/shared/Paragraph"
import * as styles from "./styles.module.scss"
import SubHeading from "../../../../components/shared/SubHeading"

const MentalHealth = ({ title, text, image }) => {
	return (
		<Section className={styles.bg}>
			<Container>
				<Row className="mt-5 mb-5 pt-5 pb-5">
					<Col md={5}>
						<div className="text-center">
							<Image src={image} fluid width={450} />
						</div>
					</Col>

					<Col md={{ span: 6, offset: 1 }} className=" pt-0 pt-md-5">
						<SubHeading className=" pt-5 text-md-start text-center ">{title}</SubHeading>
						<Paragraph className="text-md-start text-center">{text}</Paragraph>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default MentalHealth
