import React from "react"
import Paragraph from "../../../../components/shared/Paragraph"
import Section from "../../../../components/shared/Section"
import SectionHeading from "../../../../components/shared/Sectionheading"
import SubHeading from "../../../../components/shared/SubHeading"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"

const AdvocateProg = ({ title, img1, img2, subtext1, subtext2, text1, text2 }) => {
	return (
		<Section>
			<Container>
				<Row className="justify-content-center">
					<Col md={8} className={styles.mainTitle}>
						<SectionHeading className="text-center text-secondary pt-md-5 pt-0">{title}</SectionHeading>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col md={6} className="d-flex">
						<Card style={{ backgroundColor: "#B5E2FF" }}>
							<Card.Body>
								<div className=" text-center">
									<Image src={img1} fluid width={200} />
									<SubHeading className="mt-4">{subtext1}</SubHeading>
								</div>
								<Paragraph className="mt-3">{text1}</Paragraph>
							</Card.Body>
						</Card>
					</Col>
					<Col md={6} className="mt-5 mt-md-0 d-flex">
						<Card style={{ backgroundColor: "#B5E2FF" }}>
							<Card.Body>
								<div className=" text-center">
									<Image src={img2} fluid width={200}/>
									<SubHeading className="mt-4">{subtext2}</SubHeading>
								</div>
								<Paragraph className="mt-3">{text2}</Paragraph>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="justify-content-center mt-5">
					<Col md={6}>
						<Paragraph className={styles.learnMore_txt}>
							To learn more about the programs and joining a future cohort, <br />
							please email
							<span> team@galeahealth.com.</span>
						</Paragraph>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default AdvocateProg
