import React from "react"
import img_hero from "../assets/images/protostar_hero_img1.png"
import logo_hero from "../assets/images/protostar_hero_logo1.png"
import mental_health_img from "../assets/images/protostar_mental_health_tennis.png"
import mental_health_img2 from "../assets/images/protostar_mental_health_JuniorTennis.png"
import advocate_img1 from "../assets/images/protostar_athlete.png"
import advocate_img2 from "../assets/images/protostart_coach.png"

export const content = {
	// Hero Section
	hero: {
		logo: logo_hero,
		text: (
			<>
				“ProtoStar Foundation, through its The Power of Mental Health® program, seeks to help junior tennis players
				understand the importance of mental health and how it could affect almost every aspect of their lives. Placing a
				priority on their overall mental health will allow them to play their best tennis, enjoy the sport, and succeed
				in life. In teaming up with Galea, ProtoStar hopes that the resources we are providing junior athletes will help
				them enhance, maintain, and optimize both their mental performance on the tennis court and mental health off the
				tennis court. The materials will complement the information offered by the American Foundation for Suicide
				Prevention (AFSP) on depression, anxiety, and suicide prevention. ”
				<br />
				<span style={{ float: "right", paddingBottom: "3rem" }}>-Gary P. Poon, President of ProtoStar</span>
			</>
		),
		image: img_hero,
	},

	// Mental Health x Tennis
	mentalHealth: {
		title: "Mental Health x Tennis",
		image: mental_health_img,
		text: (
			<>
				In the world of competitive tennis, injuries, the pressures of year-round competition, and common challenges
				like perfectionism, OCD, and burnout increase the stress that young tennis players experience. Learning how to
				manage stress and prioritize mental health are crucial aspects of adolescent development, emotional well-being,
				and athletic performance.
				<br />
				<br />
				Through our partnership with the Matt Stevenson Junior Tennis Tournament (NY), Galea offers tennis players
				access to a network of licensed sport psychologists, therapists, nutritionists, and certified mental performance
				coaches in the tri-state area. Galea’s providers can support tennis players at all levels, from youth to pro, as
				they navigate challenges and pursue their goals in tennis and beyond.
			</>
		),
	},

	// Matt Stevenson Junior Tennis Tournament (NY)
	juniorTennis: {
		title: "Matt Stevenson Junior Tennis Tournament (NY)",
		image: mental_health_img2,
		text: (
			<>
				As sport psychologists are now a regular presence on the professional tour, the Matt Stevenson Junior Tennis
				Tournament (NY) brings awareness about mental health issues to the junior level. Junior tennis players may be
				especially vulnerable to mental health challenges because they are already dealing with the general ups and
				downs of being an adolescent. On top of competitive tennis, vying for limited spots on a tennis team, seeking
				recruitment at a Division I school, or pursuing a tennis scholarship only adds to the pressures they face.
				<br />
				<br />
				The Tournament’s goal is to help junior players understand the interrelationship between mental performance on
				the tennis court and mental health off the tennis court. Through informational materials provided at the
				Tournament, we strive to educate junior players about mental health, destigmatize mental illness, and bring the
				community together to address the youth mental health crisis.
			</>
		),
	},

	// Galea x SoCal Mental Health Advocate Programs
	AdvocateProg: {
		title: <> Galea <span>x</span> Matt Stevenson Mental Health Advocate Programss</>,
		img1: advocate_img1,
		subtext1: "For athletes",
		text1: (
			<>
				The Galea x SOCAL advocate program empowers athletes to advocate for athlete mental health through education,
				peer support, and leadership training.
				<ul>
					<li>8 weeks</li>
					<li>1 hour virtual workshop with your athlete cohort covering different mental health topics each week</li>
					<li>
						Athletes who complete the program will be listed on Galea’s athlete advocate portal alongside SOCAL,
						collegiate, and professional athlete advocates
					</li>
				</ul>
			</>
		),
		img2: advocate_img2,
		subtext2: "For parents & coaches",
		text2: (
			<>
				Galea’s Parent & Coach Mental Health Awareness Program provides parents and coaches with tools and education to
				support athlete mental health and sport enjoyment.
				<ul>
					<li>4 weeks</li>
					<li>45 minute virtual workshop each week</li>
					<br />
					<br />
				</ul>
			</>
		),
	},

	// Learn More
	LearnMore: {
		title: "Interested in learning more?",
		text: "E-mail us with questions, concerns, or suggestions.",
	},
}
