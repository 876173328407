import React from "react"
import Section from "../../../../components/shared/Section"
import { Col, Container, Image, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import Paragraph from "../../../../components/shared/Paragraph"

const HeroPacific = ({ logo, text, img }) => {
	return (
		<Section className={styles.pacificBg}>
			<Container>
				<Row className="pt-5">
					<Col xl={5}  className={styles.marginBottom}>
						<Image src={logo} fluid className="pt-md-5 pt-0" />
						<Paragraph className="mt-3 mb-0 mb-xl-5 pb-0 pb-xl-5">{text}</Paragraph>
					</Col>
					<Col xl={{ span: 6, offset: 1 }}>
						<div className="text-center mt-0 mt-md-5 pt-0 pt-md-5">
						<Image src={img} fluid className="pb-md-5 pb-5 " />
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default HeroPacific
